<template>
  <router-link :to="to" class="nav-link" :class="{ 'active-nav-link': isActive }" aria-current="page">
    {{ label }}
  </router-link>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps(['to', 'label']);
const route = useRoute();

const isActive = computed(() => {
  return route.matched.some(record => record.path === props.to);
});
</script>

<style scoped>
.nav-link {
  text-decoration: none;
  color: #d1d5db;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #4b5563;
  color: #ffffff;
}

.active-nav-link {
  background-color: #111827;
  color: #ffffff;
}
</style>
