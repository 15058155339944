<template>
    <div @click="scrollToSection(props.to)" class="cursor-pointer">
        <slot></slot>
    </div>
</template>
  
<script setup>
import { defineProps } from 'vue';

const props = defineProps(['to']);

const scrollToSection = () => {
    console.log("Clicked");
    const element = document.getElementById(props.to.toLowerCase());
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 50, // Adjust as needed
            behavior: 'smooth',
        });
    }
};
</script>