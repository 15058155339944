<template>
    <button type="submit"
        class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#f97316] hover:bg-[#e36812] focus:shadow-outline focus:outline-none">
        {{ buttonText }}
    </button>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
    text: String,
});

const buttonText = ref(props.text);
</script>