<template>
    <router-link :to="to" class="nav-link" :class="{ 'active-nav-link': isActive }" aria-current="page">
        {{ label }}
    </router-link>
</template>
  
<script setup>
import { defineProps, computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps(['to', 'label']);
const route = useRoute();

const isActive = computed(() => {
    return route.matched.some(record => record.path === props.to);
});
</script>
  
<style scoped>
.nav-link {
    color: #d1d5db;
    background-color: transparent;
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
    background-color: #4b5563;
    color: #ffffff;
}

.active-nav-link {
    background-color: #111827;
    color: #ffffff;
}</style>
  