<template>
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-40">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                We can handle it all!
            </h2>
            <p class="text-base text-gray-700 md:text-lg">
                Our extensive backgrounds allow us to take on whatever project you have and deliver an excellent product!
            </p>
        </div>
        <div class="grid gap-8 row-gap-10 lg:grid-cols-2">
            <div class="max-w-md sm:mx-auto sm:text-center">
                <div
                    class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                    <svg class="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16" stroke="currentColor"
                        viewBox="0 0 52 52">
                        <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                            points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                    </svg>
                </div>
                <h6 class="mb-3 text-xl font-bold leading-5">Front End Development</h6>
                <p class="mb-3 text-sm text-gray-900">
                    Some sentence about designs and stuff
                </p>
            </div>
            <div class="max-w-md sm:mx-auto sm:text-center">
                <div
                    class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                    <svg class="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16" stroke="currentColor"
                        viewBox="0 0 52 52">
                        <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                            points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                    </svg>
                </div>
                <h6 class="mb-3 text-xl font-bold leading-5">Back End Development</h6>
                <p class="mb-3 text-sm text-gray-900">
                    Long sentence about making this work fast, secure, and reliably
                </p>
            </div>
            <div class="max-w-md sm:mx-auto sm:text-center">
                <div
                    class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                    <svg class="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16" stroke="currentColor"
                        viewBox="0 0 52 52">
                        <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                            points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                    </svg>
                </div>
                <h6 class="mb-3 text-xl font-bold leading-5">Hosting & Scaling</h6>
                <p class="mb-3 text-sm text-gray-900">
                We'll make sure your website is up and running, and it handles any large influxes of users
            </p>
        </div>
        <div class="max-w-md sm:mx-auto sm:text-center">
            <div
                class="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50 sm:mx-auto sm:w-24 sm:h-24">
                <svg class="w-12 h-12 text-deep-purple-accent-400 sm:w-16 sm:h-16" stroke="currentColor"
                    viewBox="0 0 52 52">
                    <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                        points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
            </div>
            <h6 class="mb-3 text-xl font-bold leading-5">Hardware Installs</h6>
            <p class="mb-3 text-sm text-gray-900">
                From home storage servers, switching & routing racks, personal computers, to any other kind of computer hardware, we can configure, test, build, and deploy it for you.
            </p>
        </div>
    </div>
</div></template>